
.right-j{
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color:#37517e;
    color: white;
    padding: 1rem 2rem;
    border-radius: 25px;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.btn-join{
    background-color: orange;
}

@media screen and (max-width:768px){
    .Join{
        flex-direction: column;
        gap: 1rem;
    }
    
    .right-j{
        padding: 2rem;
    }
}